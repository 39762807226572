import * as React from 'react';
import Layout, { Column, Row, Section } from '../components/layout';
import SEO from '../components/seo';
import SectionIcon from '../components/section-icon';
import GradientText from '../components/gradient-text';
import { ThemeColor } from '../gatsby-theme-material-ui-top-layout/theme';
import { Link } from 'gatsby-theme-material-ui';
import { Box, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled('li')`
  margin-bottom: 12px;
`;

const Phase: React.FC<{
  icon: string;
  title: string;
  subtitle: string;
  gradient: keyof ThemeColor;
  color: keyof ThemeColor;
}> = ({ icon, title, subtitle, gradient, color, children }) => (
  <Section id={title.toLowerCase().replace(' ', '')}>
    <Box maxWidth={600}>
      <Row gap={4} alignItems="center" pb={4}>
        <SectionIcon
          size={72}
          icon={<SvgIcon component={require(`../images/${icon}.svg`)} />}
          background={gradient}
        />
        <Column>
          <GradientText variant="h2" gradient={gradient}>
            {title}
          </GradientText>
          <GradientText variant="h3" gradient={color}>
            {subtitle}
          </GradientText>
        </Column>
      </Row>
      <Typography>{children}</Typography>
    </Box>
  </Section>
);

const RoadmapPage: React.FC = () => {
  return (
    <Layout gradientHeight={[300, 300, 300]}>
      <SEO title="Roadmap" />
      <Column gap={8}>
        <Section height={300} background={'uncommonGradient2'} skewedBottom>
          <Column pt={[10, 8]} color="white">
            <Typography variant="h1">Roadmap</Typography>
            <Box pt={2} />
            <Typography variant="h5">
              Like the MCU, Kanjiverse will come to you in 4 phases.
            </Typography>
          </Column>
        </Section>
        <Phase
          icon="brain"
          title="Phase 1"
          subtitle="Learner's Dictionary App"
          gradient="commonGradient1"
          color="common"
        >
          This app is the core of the <i>Kanjiverse ecosystem</i>. Currently in
          development, it will enter in <i>private beta</i> somewhere in
          September 2020. More than 150 features are planned for Phases 1 and 2,
          and will be rolled out gradually over the coming months.
          <ul>
            <Item>
              <b>Modern design. </b>A better looking UI and smoother UX than
              most of the dictionary apps on the market. Input Japanese faster
              with auto-suggestions or choose from a hundred filters to create
              custom lists. Results are presented in the form of clear and
              customizable cards such as <i>Glyph Graph</i>, <i>Mnemonic Box</i>
              , or <i>Readings List</i>.
            </Item>
            <Item>
              <b>Multi-platform. </b>Available as a native app on iOS and
              Android, a web app in your browser or on your desktop. Sync your
              preferences, custom lists and contributions to the cloud, and
              access them on all your devices.
            </Item>
            <Item>
              <b>Adapt to your level. </b>The interface is customized to show
              easier content to beginners (like romaji, stroke orders, and tips
              to remember kanji) and more details to advanced learners (such as
              rare readings and kyujitai).
            </Item>
            <Item>
              <b>Real examples. </b>Kanjiverse's very own corpus of sample
              sentences is compiled from different sources such as Wikipedia,
              newsgroup, anime and dramas subtitles. You don't have to use
              textbooks' canned material anymore and can learn with what you
              care about.
            </Item>
          </ul>
          Learn more about this app and join the beta <Link to="/">there</Link>.
        </Phase>
        <Phase
          icon="brain"
          title="Phase 2"
          subtitle="Collaboration Features"
          gradient="uncommonGradient1"
          color="uncommon"
        >
          Aside from classic community forums and chat rooms, collaborative
          features will be integrated into the app once Phase 1 is rolled out.
          <ul>
            <Item>
              <b>Vocab lists. </b>
              Build your own lists of word/kanji, keep them private or share
              them with the community. Search lists contributed to the
              <i> marketplace</i>, such as lesson vocabulary for your textbook
              or must-know words from your favorite anime.
            </Item>
            <Item>
              <b>Mnemonics and notes. </b>
              You can write your own mnemonics to remember kanji/words and share
              them. Lacking inspiration? Use someone else's and upvote it.
            </Item>
            <Item>
              <b>Translations. </b>A sentence is missing a translation in your
              language? Submit your own translation or check others'
              contributions, they are ranked according to the author's fluency
              and votes from other members.
            </Item>
            <Item>
              <b>Third-party integration. </b>
              Export and import your progress from your favorite app such as
              <i> Anki</i> or <i>WaniKani</i>. Send your contributions to open
              projects like <i>Kanjidic</i> and <i>Tatoeba Project</i> without
              leaving the app.
            </Item>
          </ul>
        </Phase>
        <Phase
          icon="brain"
          title="Phase 3"
          subtitle="For Content Writers"
          gradient="rareGradient1"
          color="rare"
        >
          Bridge the gap between content writers and their audience with tools
          to help bloggers and teachers write lessons that integrate with the
          <i> Kanjiverse ecosystem</i>. The development hasn't been scheduled
          yet but will likely overlap with Phase 2 and the release of the web
          app.
          <ul>
            <Item>
              <b>Embed Cards. </b>
              Illustrate your explanations by embedding in your page
              Kanjiverse's cards such as <i>Word Senses</i>,
              <i> Glyph Decomposition</i>, or <i>Sample Sentences</i>.
            </Item>
            <Item>
              <b>Share Vocab List. </b>
              Create a vocab list for your lesson and share its link to let your
              readers add it to their collection.
            </Item>
            <Item>
              <b>Text Scanner. </b>
              Color all words by frequency of usage to highlight difficult
              words. Calculate a <i>difficulty metric</i> to help you assess if
              a text is appropriate to your audience's level.
            </Item>
            <Item>
              <b>Micro Lessons. </b>
              Don't have your own blog? Write a <i>micro lesson</i> directly on
              Kanjiverse's blogging platform. To stay true to Kanjiverse's
              motto, use <i>real</i> text, like a song's lyrics or a movie
              scene.
            </Item>
          </ul>
        </Phase>
        <Phase
          icon="brain"
          title="Phase 4"
          subtitle="Gamified Learning Path"
          gradient="epicGradient1"
          color="epic"
        >
          The <i>Learner's Dictionary</i> will remain a standalone app, but will
          integrate with a separate <i>gamified learning app</i> that provides a
          guided study path. Its development should start after Phase 3,
          somewhere in 2021. Its ambition is to overcome the shortcomings of a
          classic SRS:
          <ul>
            <Item>
              <b>Smarter algorithm. </b>
              Rather than naively increasing the time between reviews, the algo
              should learn from your style and habits to optimize the review
              schedule, for instance by giving a bonus to all words in a
              sentence that you've just read.
            </Item>
            <Item>
              <b>Better quizzes. </b>
              Standard recall and recognition flashcards are dull and boring.
              Instead, there should be a mix of different kinds of quizzes
              (similar to the JLPT's vocabulary section such as cloze test,
              synonym, or reading) to help you review one concept from different
              angles and not get bored in the process.
            </Item>
            <Item>
              <b>Competitive. </b>
              Keeps you motivated with <i>leaderboards</i> and{' '}
              <i>battle mode</i> to compete against your peers.
            </Item>
            <Item>
              <b>Tailor-made. </b>
              Builds the content of the learning path to match your level and
              interests (e.g. anime for beginners, or news for advanced).
            </Item>
            <Item>
              <b>Integrate with the ecosystem. </b>
              Reflects your progress in the dictionary (in custom vocab lists)
              and the community forums (with badges).
            </Item>
          </ul>
        </Phase>
        <Box pt={8} />
      </Column>
    </Layout>
  );
};

export default RoadmapPage;
